import React from 'react';
import ReactDOM from 'react-dom';
import './app.css';

const spinner =
  <div className='spinner'>
    <div className='spinner-point'></div>
    <div className='spinner-point'></div>
    <div className='spinner-point'></div>
    <div className='spinner-point'></div>
    <div className='spinner-point'></div>
    <div className='spinner-point'></div>

    <div className='spinner-line'></div>
    <div className='spinner-line'></div>
    <div className='spinner-line'></div>
    <div className='spinner-line'></div>
    <div className='spinner-line'></div>
    <div className='spinner-line'></div>
  </div>

class AuthForm extends React.Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    let resp = await fetch('/cloud/auth', {
      method: 'POST',
      body: data,
    }).then(function (resp) {
      return resp.json();
    });

    console.log(resp);

    if (resp.error || resp.message) {
      this.error(resp.error || resp.message);
    } else {
      this.redirect(resp.host, resp.token);
    }
  }

  redirect(host, token) {
    let url = `https://${host}.${window.location.host}/cloud/host`;
    const elem =
      <main className='app'>
        <form method='post' action={url}>
          <input type='hidden' name='token' value={token} />
        </form>
      </main>

    ReactDOM.render(elem, document.getElementById("root"));
    document.querySelector("form").submit();
  }


  error(msg) {
    const elem = <div className='error'>{msg}</div>;
    ReactDOM.render(null, document.getElementById("messages"));
    ReactDOM.render(elem, document.getElementById("messages"));
  }

  render() {
    return (
      <main className='app'>

        <section className='card'>
          <div id='messages' className='messages'></div>
          {spinner}
          <form onSubmit={this.handleSubmit}>
            <p><input type='text' name='username' placeholder='user@host' required /></p>
            <p><input type='password' name='password' placeholder='password' required /></p>
            <p><button>Sign In</button></p>
          </form>
        </section>

        <p className='subtitle'>supported by <a href='https://neirolis.com'>Neirolis</a></p>
      </main>
    );
  }
}

export default AuthForm;

